<template>
    <form class="contact-form form">

        <label class="form__label" for="contact-form__name" id="contact-form__name-label">{{ t('nameLabel') }}</label>
        <input class="form__input" type="text" id="contact-form__name" name="name" :disabled="isInputDisabled" :placeholder="t('namePlaceholder', '')" v-model="contactName" />

        <label class="form__label" for="contact-form__email" id="contact-form__email-label" :class="emailLabelClass">{{ t('emailLabel') }}</label>
        <input class="form__input" type="email" id="contact-form__email" name="email" :disabled="isInputDisabled" :placeholder="t('emailPlaceholder', '')" v-model="contactEmail" />

        <label class="form__label" for="contact-form__message" id="contact-form__message-label">{{ t('messageLabel') }}</label>
        <textarea class="form__textarea" name="message" id="contact-form__message" :disabled="isInputDisabled" :placeholder="t('messagePlaceholder', '')" v-model="contactMessage"></textarea>

        <div class="form__checkbox-label" id="contact-form__disclaimer">
            <input class="form__checkbox" type="checkbox" id="contact-form__disclaimer-checkbox" :disabled="isInputDisabled" v-model="contactDisclaimer" />
            <label class="text" for="contact-form__disclaimer-checkbox" v-html="t('disclaimerLabel')" />
        </div>

        <div v-if="success && !error" class="form__response-message form__success">
            <p>{{ t('formSuccessMessage') }}</p>
        </div>

        <div v-if="success && error" class="form__response-message form__error ">
            <p>{{ t(error) }}</p>
        </div>


        <hpi-button v-if="!success || error" id="contact-submit-button" @click.prevent="handleSubmit" variant="solid" style="primary" :loading="loading" :disabled="isButtonDisabled" icon-position="right">
            <template #loading-indicator><slot name="loading-icon" /></template>
            {{ t('submitButtonLabel') }}
        </hpi-button>

    </form>
</template>

<script>
import { emailIsValid } from '@/utils/EmailValidator.js';

export default {
    name: 'ContactForm',

    props: {
        texts: Object,
    },

    data() {
        return {
            contactName: '',
            contactEmail: '',
            contactSubject: '',
            contactMessage: '',
            contactDisclaimer: false,

            success: false,
            loading: false,
            error: null,
        }
    },

    created() {
        this.contactSubject = '[WEBSITE Kontaktanfrage]: Neue Anfrage von deiner Website'
    },

    computed: {

        isButtonDisabled() {
            return !this.contactName || !this.contactEmail || this.emailInvalid || !this.contactSubject || !this.contactMessage || !this.contactDisclaimer;
        },

        isInputDisabled() {
            return this.loading || this.success && !this.error;
        },

        emailInvalid() {
            return !!this.contactEmail.trim().length && !emailIsValid(this.contactEmail);
        },

        emailLabelClass() {
            return {
                'form__input--invalid': this.contactEmail.length > 0 && !emailIsValid(this.contactEmail)
            }
        },
    },


    methods: {
        async handleSubmit() {

            if (this.isButtonDisabled) { return; }

            this.error = null;
            this.success = false;
            this.loading = true;

            var bodyFormData = new FormData();
            bodyFormData.set('payload', JSON.stringify({
                name: this.contactName,
                email: this.contactEmail,
                subject: this.contactSubject,
                message: this.contactMessage,
                disclaimer: this.contactDisclaimer
            }));

            const { data } = await this.$axios.post('/actions/site-module/contact-form/submit', bodyFormData);

            // const data = {
            //     // error: 'errorFormFieldRequired',
            //     success: true
            // };

            if (data.hasOwnProperty('error')) {
                this.error = data.error;
            }

            this.loading = false;
            this.success = true;
        },

        t(key, fallback) {
            if (!this.texts || !this.texts.hasOwnProperty(key)) {
                return fallback != null ? fallback : key;
            }

            return this.texts[key];
        },
    },
}
</script>
