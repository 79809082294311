import "./scss/global.scss";
import 'swiper/css';

import { ExpandTransition, FadeTransition } from '@bam/transitions';
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { createApp } from 'vue';
import { useBreakpoints } from '@vueuse/core';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import axios from 'axios'

gsap.registerPlugin(ScrollTrigger);


const components = import.meta.globEager('./components/**/*.vue')

const bps = JSON.parse(getComputedStyle(document.body).getPropertyValue('--bam-breakpoints'));
const breakpoints = useBreakpoints(bps);


const app = createApp({
    data: () => {
        return {
            swiperModules: [
                Navigation,
                Pagination
            ],
            overlays: {},
            isInMobileNavBp: breakpoints.smaller('mobile-menu-bp')
        };
    },

    mounted() {

        // var contentAreas = gsap.utils.toArray(".section-media-content__content-area");
        // var mediaAreas = gsap.utils.toArray(".section-media-content__media");

        // contentAreas.forEach(function(elem){

        //     gsap.fromTo(elem,
        //     {
        //         opacity: 0.5,
        //         y: '50%',
        //     },
        //     {
        //         scrollTrigger: {
        //             trigger: elem,
        //         },
        //         y: 0,
        //         opacity: 1,
        //         duration: 0.250
        //     }
        //     );
        // });

        // mediaAreas.forEach(function(elem){

        //     gsap.fromTo(elem,
        //     {
        //         opacity: 0.5,
        //         y: '50%',
        //     },
        //     {
        //         scrollTrigger: {
        //             trigger: elem,
        //         },
        //         y: 0,
        //         opacity: 1,
        //         duration: 0.5,
        //         delay: 0.5
        //     }
        //     );
        // });


    },

    provide() {
        return {
            registerOverlay: this.registerOverlay,
            deRegisterOverlay: this.deRegisterOverlay,
            getOverlay: this.getOverlay,
        }
    },

    methods: {

        registerOverlay(id, overlay) {
            this.overlays[id] = overlay;
        },

        deRegisterOverlay(id) {
            delete this.overlays[id];
        },

        getOverlay(id) {
            return this.overlays[id];
        }
    }
})


app.config.globalProperties.$breakpoints = breakpoints;
app.config.globalProperties.$gsap = gsap;

app.config.globalProperties.$axios = axios.create({
    baseURL: '',
    withCredentials: true,
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-TOKEN': window.csrfTokenValue,
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT',
     }
});

Object.entries(components).forEach(([ path, definition ]) => {
    const componentName = path.split('/').pop().replace(/\.\w+$/, '')
    app.component(componentName, definition.default)
});

app.component('expand-transition', ExpandTransition);
app.component('fade-transition', FadeTransition);

app.component('swiper', Swiper);
app.component('swiper-slide', SwiperSlide);

app.mount('#app');
